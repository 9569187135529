import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ShoppingCartProvider } from "./components/_default/context/ShoppingCartContext"
import store from "./store/store"
import Redirect from "./components/_default/utilities/Redirect"
import TagManager from 'react-gtm-module'
// Pages
import Webpage1Varuosad from "./pages/Webpage1Varuosad";
import Webpage2K__psised from "./pages/Webpage2K__psised";
import Webpage3Lehte_ei_leitud from "./pages/Webpage3Lehte_ei_leitud";
import Webpage4Tellimus from "./pages/Webpage4Tellimus";
import Webpage5Toode from "./pages/Webpage5Toode";

var baseURL = 'https://laitserallypark.kassahaldus.ee/webstoreApi';
var axiosHeaders = {
    Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI1IiwianRpIjoiNGNhNGU1N2UyMjU2YTE0NjdiOWNjZWU1Yjc4NjU3MDQwMDg0YTMyZjY2YzZkOGMyZGQyMWY4MzhmZWFhZTllYTdlMDJkZWU3MTBiZmE1ZWIiLCJpYXQiOjE3MjY1NzU5MzAuMTg0OTU1LCJuYmYiOjE3MjY1NzU5MzAuMTg0OTYsImV4cCI6MTcyNjY2MjMzMC4xODAzOTQsInN1YiI6IjkxIiwic2NvcGVzIjpbXX0.R7OBe9LzsROh7M6WV50UJQP4PUbogb3Xtv5FMQ1rsM0LfbS-oViVPad5_TAT4sAgKJcxK_E1BWYfDGIMB2XndqsaMhqHCTb9k21LRoNspRGQoKZofI20QMCztUAjMato3wFtPs7IJYPjjMrbHvjkNAL0pbyCCBx5sTGlOqUKGKOUJGMtHgJWbQBoPFDMGlHWtDoX7GY09EL9DX284KOt1G2ohPkENbENm9vhobJHD9v3AqdCAe98-gIeW60vjVtSi_XjU9gLSlolUpQbCpt_RhRAnssCk-lDBsWwGTZEqQkF9ViIrWa1oqjpWk7qLE4XHMTcqMOH3-h7HA9Q_JQxEd81DQRzKHZfdth1HyjRuo6b_o6g4GJVkBfzHBIx8WiVPi_wlvBjW3zGrshsnnMOTvLkgCLxG5TqcbvFV_zFkJb6lTPyvMtiT8PHQdw1AmZ2jmzo1BJlB4Qqfr5TtayX4o7YZ51ywV0ffTZQbcmPPvd6NQK24KyEoOIFNsQ_n2uFut-2x37ADXgnGrLSYB2XvBo0Q2q5W4c91b7wyZe1G6ZiCK_A0tf0tyHKgivUCacolKNg-NcquM0U8bQRGoVRFfJOOONB9wt4g-uvby5WeJGDqwBlmu3BuBFlGVAvmQBOgZuX2uaT3oJPVTdkjZcEAdADmxHtmiR1kZMZ2l77Dow',
    'Access-api-key': "eyJpdiI6InZmUGhoTjNlTUJnN3hVL0NFRlB1dnc9PSIsInZhbHVlIjoiNk5sL2hsanp0OENuRnVvc09qdG1PUT09IiwibWFjIjoiZTA0NmI2OTUzYTI1ZGNlOTY4NTcyODk3MmE2ZTA2ZDg2YzkxODM1NmViOWE1MGM1NDMxNDEwNmE2YTAwZDYzYiIsInRhZyI6IiJ9",
    'Access-api-id': "eyJpdiI6IlNvZk9ZNWVrdVZGdVlSSmpVeHEzenc9PSIsInZhbHVlIjoiVU1aZjFZOS9lYmpUV3B3M3p4Kzc1QT09IiwibWFjIjoiMmZiNWYyODQzYmU4NGJmMjU0YjFkNTQ2YjRiNWI1NTA4MjcyZmJhOWI0MDYzYTUwZjIzZGEzZjZiNTEzNzBhNCIsInRhZyI6IiJ9",
    'Access-Control-Allow-Origin': "*"
 };


window.axios = require('axios').create({
  baseURL: baseURL,
  headers: axiosHeaders
});
const tagManagerArgs = {
    gtmId: ""
}
TagManager.initialize(tagManagerArgs)
    
export default function App() {
    window.dataLayer.push({
        event: 'pageview'
    });
    return (
        <Provider store={store}>
            <ShoppingCartProvider>
                <BrowserRouter basename="/">
                    <Routes>
                                            <Route path="/et/et" element={<Webpage1Varuosad />} exact />;
                                                                    <Route path="/et/kupsised" element={<Webpage2K__psised />} exact />;
                                                                    <Route path="/et/*" element={<Webpage3Lehte_ei_leitud />} exact />;
                                                <Route path="*" element={<Webpage3Lehte_ei_leitud />} exact />;
                                                                    <Route path="/et/tellimus" element={<Webpage4Tellimus />} exact />;
                                                                    <Route path="/et/toode/:id" element={<Webpage5Toode />} exact />;
                                                                
                                                            </Routes>
                </BrowserRouter>
            </ShoppingCartProvider>
        </Provider>
    );
}